import { CategoryFilterType } from '../shared/filter-bar/FilterBar';
import { Taxonomy } from '@/models/interfaces/utils/utils';

export const extractCategoryFilter = (taxonomies: Taxonomy[], slug?: string) => {
  let filters: Taxonomy[] = [];

  if (slug) {
    filters = taxonomies?.filter((item) => item.slug === slug) ?? [];
  } else {
    filters = taxonomies?.filter((item) => item.slug !== 'gal') ?? [];
  }

  return filters.map((item) => {
    return [
      {
        id: '1000',
        name: 'Tutti',
        type: 'category',
      },

      ...item.children.map((item) => ({
        id: String(item.id),
        name: item.nome,
        type: item?.type ?? '',
        permalink: item?.permalink ?? '',
      })),
    ];
  }) as CategoryFilterType[][];
};

export const extractGalFilter = (taxonomies: Taxonomy[]) => {
  const galFilters = taxonomies?.filter((item) => item.slug === 'gal')[0]?.children ?? [];

  return [
    {
      id: '1000',
      name: 'Calabria',
    },
    ...galFilters.map((item) => ({
      id: String(item.id),
      name: item.nome,
    })),
  ];
};

export const removeDuplicateObjects = (array: any[], property: string) => {
  const uniqueIds: any[] = [];

  const unique = array.filter((element) => {
    const isDuplicate = uniqueIds.includes(element[property]);

    if (!isDuplicate) {
      uniqueIds.push(element[property]);

      return true;
    }

    return false;
  });

  return unique;
};
