import { MapItem } from '../Map';

export const getBoundsForPoints = (items: MapItem[]) => {
  if (items?.length === 0) return null;

  const pointsLong = items?.map((item) => item.longitude);
  const pointsLat = items?.map((item) => item.latitude);

  if (!pointsLong || !pointsLat) return null;

  const minLong = Math.min(...pointsLong);
  const maxLong = Math.max(...pointsLong);
  const minLat = Math.min(...pointsLat);
  const maxLat = Math.max(...pointsLat);

  const sw = [minLong, minLat];
  const ne = [maxLong, maxLat];

  if (!minLong || !maxLong || !minLat || !maxLat) return null;

  return [sw, ne];
};
