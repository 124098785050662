import clsx from 'clsx';
import React, { memo } from 'react';

interface ContentHTMLProps {
  content: string;
  className?: string;
}

const ContentHTML = ({ content, className }: ContentHTMLProps) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: content,
      }}
      className={clsx('content flex flex-col gap-4', className)}
    />
  );
};

export default memo(ContentHTML);
