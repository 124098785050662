import React, { memo, useContext } from 'react';
import { HiLocationMarker } from 'react-icons/hi';
import { ThemeContext } from '../../utility/theme/ThemeContext';

interface PinProps {
  className?: string;
}

const Pin = ({ className = 'h-8 w-8 animate-fadeIn', ...props }: PinProps) => {
  const { theme } = useContext(ThemeContext);

  return (
    <HiLocationMarker
      stroke={theme === 'light' ? '#fff' : 'rgb(255,255,255,0.28)'}
      strokeWidth={0.5}
      {...props}
      className={' text-primary-500 ' + className}
    />
  );
};

export default Pin;
