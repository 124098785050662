import Title from '@/components/core/title/Title';
import IconMapping from '@/components/utils/iconMapping';
import { EntityType, FilieraEntityType } from '@/models/entity-type/EntityType';
import clsx from 'clsx';
import Link from 'next/link';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import imgMichelin from '@/assets/images/stella-michelin.png';
import Image from 'next/image';

export type ArchiveItem = {
  id: string;
  title: string;
  address?: string;
  type?: string;
  permalink?: string;
  latitude?: number;
  longitude?: number;
};

interface FilieraArchiveCardProps {
  item: ArchiveItem;
  className?: string;
  selected?: boolean;
  type?: FilieraEntityType;
  url?: string;
  preventDefault?: boolean;
  scrollTo?: boolean;
  isStar?: boolean;
  onRef?: (ref: HTMLAnchorElement) => void;
  onMouseEnter?: (item: ArchiveItem) => void;
  onMouseLeave?: (item: null) => void;
  onClick?: (item: ArchiveItem) => void;
  onChangeSelected?: (ref: React.RefObject<HTMLAnchorElement>) => void;
}

const FilieraArchiveCard = ({
  className,
  item,
  selected,
  type,
  url,
  scrollTo,
  isStar = false,
  preventDefault,
  onChangeSelected,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: FilieraArchiveCardProps) => {
  const ref = useRef<HTMLAnchorElement>(null);

  const parsedType = (type ?? item.type ?? EntityType.AGRITURISMI).toLowerCase() as FilieraEntityType;

  const IconComponent = type || item.type ? IconMapping[parsedType]?.thin : null;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      preventDefault && e.preventDefault();
      preventDefault && e.stopPropagation();
      onClick?.(item);
    },
    [item, onClick, preventDefault],
  );

  useEffect(() => {
    if (ref.current && selected && onChangeSelected) {
      onChangeSelected?.(ref);
    }
  }, [selected, onChangeSelected]);

  return (
    <Link
      ref={ref}
      href={url || item?.permalink || '/'}
      onClick={handleClick}
      onMouseEnter={() => onMouseEnter?.(item)}
      onMouseLeave={() => onMouseLeave?.(null)}
      className={clsx(
        'group relative block h-full w-full overflow-hidden rounded-md bg-gray-light shadow-md transition-all duration-200 hover:!bg-primary-500 dark:bg-gray-dark',
        className,
        parsedType,
        item?.type,
        {
          '!bg-primary-500': selected,
        },
      )}
    >
      <div className={clsx('absolute inset-0 transition-all duration-300')}>
        <div className="flex h-full w-full flex-col items-start justify-between p-4">
          <div className="relative flex w-full justify-between">
            <Title
              heading="h3"
              sizeBar="sm"
              className={clsx('text-lg font-medium group-hover:!text-white', {
                '!text-white': selected,
              })}
            >
              {item?.title}
            </Title>

            {isStar ? (
              <Image src={imgMichelin} alt="michelin_logo" className=" h-10 w-10 rounded-full bg-red-700" />
            ) : null}

            {/* <span className="absolute left-0 top-0 h-full w-1.5 rounded-full bg-primary-600 transition-all duration-300 " />
            <div className="relative z-10 translate-x-4 text-start transition-all duration-300 ">
              <h3
                className={clsx('line-clamp-4 text-lg font-medium', {
                  '!text-white': selected,
                })}
              >
                {item.title}
              </h3>
            </div> */}
          </div>
          {item?.address ? (
            <p
              className={clsx('text-sm group-hover:!text-white', {
                '!text-white': selected,
              })}
            >
              {item.address}
            </p>
          ) : null}
        </div>
        {/* <Image blurDataURL="/placeholder.png"  placeholder="blur"  src={shapeSVG} alt="shape" className="h-24 w-full" /> */}
      </div>

      {IconComponent ? (
        <div className="group absolute inset-0 flex items-center justify-end">
          <div
            className={clsx('h-full scale-90 group-hover:mix-blend-screen dark:mix-blend-screen', {
              'mix-blend-screen': selected,
            })}
          >
            <IconComponent
              className={clsx(
                'h-full w-auto text-stone-400/70 opacity-10 transition-all duration-200 group-hover:text-stone-200 dark:text-stone-600',
                {
                  'text-stone-200': selected,
                },
              )}
            />
          </div>
        </div>
      ) : null}

      <span className="absolute inset-x-0 bottom-0 h-[6px] w-full bg-primary-600" />
    </Link>
  );
};

export default memo(FilieraArchiveCard);
