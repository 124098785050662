import CustomTransition from '@/components/core/custom-transition/CustomTransition';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Map, { Marker } from 'react-map-gl';
import { BeatLoader } from 'react-spinners';
import Pin from './components/Pin';
import { getBoundsForPoints } from './utils/utilsMap';

import 'mapbox-gl/dist/mapbox-gl.css';

export type MapItem = {
  id: string;
  title: string;
  longitude: number;
  latitude: number;
};

interface MapsProps {
  items: MapItem[];
  selectedItem: any | null;
  theme?: 'light' | 'dark';
  isLoading?: boolean;
  initialViewState?: {
    latitude: number;
    longitude: number;
    zoom: number;
  };
  zoom?: number;
  children?: React.ReactNode;
  onRef?: (ref: any) => void;
  onSelectItem?: (item: any) => void;
}

const initialViewStateBase = {
  latitude: 39.25168465331211,
  longitude: 16.543546420855343,
  zoom: 8,
};

const Maps = ({
  items,
  selectedItem,
  isLoading = false,
  theme = 'light',
  initialViewState,
  zoom,
  children,
  onRef,
  onSelectItem,
}: MapsProps) => {
  const mapRef = useRef<any>();

  const Pins = useMemo(() => {
    // eslint-disable-next-line react/display-name
    return () => (
      <>
        {items.map((item, index) => (
          <Marker
            key={`marker-${item.id}`}
            longitude={item.longitude}
            latitude={item.latitude}
            anchor="center"
            onClick={(e: any) => {
              // If we let the click event propagates to the map, it will immediately close the popup
              // with `closeOnClick: true`
              onSelectItem?.(item);
              e.originalEvent.stopPropagation();
            }}
            style={{ cursor: 'pointer' }}
          >
            <Pin />
          </Marker>
        ))}
      </>
    );
  }, [items]);

  const centerMap = useCallback(() => {
    const bounds = getBoundsForPoints(items);

    const options: any = {
      padding: 50,
      duration: 500,
      zoom: zoom,
      maxZoom: 12,
    };

    Object.keys(options).forEach((key) => {
      if (options[key] === undefined) {
        delete options[key];
      }
    });

    if (mapRef?.current && bounds) {
      mapRef.current?.getMap()?.fitBounds(bounds, options);
    }
  }, [items, Pins]);

  const onLoad = useCallback(() => {
    centerMap();
    if (mapRef.current) {
      mapRef.current?.getMap()?.resize();
      onRef?.(mapRef.current);
    }
  }, [centerMap]);

  useEffect(() => {
    centerMap();
  }, [centerMap]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current?.getMap()?.resize();
      onRef?.(mapRef.current);
    }
  }, [mapRef.current]);

  return (
    <Map
      ref={mapRef}
      onLoad={onLoad}
      initialViewState={initialViewState ?? initialViewStateBase}
      style={{
        height: '100%',
        width: '100%',
      }}
      mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_TOKEN}
      mapStyle={theme == 'light' ? 'mapbox://styles/mapbox/streets-v9' : 'mapbox://styles/mapbox/dark-v11'}
      attributionControl={false}
    >
      <Pins />

      {children}

      <CustomTransition show={isLoading}>
        <div className="absolute inset-x-0 top-4 mx-auto h-fit w-fit rounded-md bg-white px-4 pb-1 pt-2">
          <BeatLoader color="#A91314" size={10} />
        </div>
      </CustomTransition>

      {/* <ScaleControl /> */}
    </Map>
  );
};

export default Maps;
